<template>
  <div class="content content_in">
    <header
      v-if="!isAuth"
      class="home-page header"
      :class="{ 'open-menu': openedSidebar }"
    >
      <div class="content content_in">
        <div class="header__menu" @click="openedSidebar = !openedSidebar">
          <div class="icon-menu" :class="{ active: openedSidebar }">
            <div class="sw-topper"></div>
            <div class="sw-bottom"></div>
            <div class="sw-footer"></div>
          </div>
        </div>
        <router-link to="/" class="land-header__logo">
          <img
            class="main-logo"
            :src="require('@/assets/images/home/logo.svg')"
            alt=""
          />
          <img
            class="mobile-logo"
            :src="require('@/assets/images/entry/logo-white.svg')"
            alt=""
          />
        </router-link>
        <div class="header__manager">
          <div class="manager">
            <div class="manager__item">
              <a
                href="https://telegram.me/PH_support_Dmitriy"
                target="_blank"
                class="manager__link"
                >@PH_support_Dmitriy</a
              >
            </div>
            <div class="manager__item pl-0">
              <a
                href="https://telegram.me/Leo31PH"
                target="_blank"
                class="manager__link"
                >@Leo31PH</a
              >
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a
                href="https://telegram.me/publisher_push_house"
                target="_blank"
                class="manager__link"
                >@publisher_push_house</a
              >
            </div>
          </div>
        </div>
        <nav class="header__entry entry-menu">
          <ul>
            <li>
              <router-link to="/login" class="btn _entry">{{
                $t("landing.login")
              }}</router-link>
            </li>
            <li>
              <router-link to="/registration" class="btn _registration">{{
                $t("landing.register")
              }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="ml-2 header__lang language">
          <select-language />
        </div>

        <router-link to="/login" class="header__login">
          <svg
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.25 20.2552H21.75C25.7541 20.2552 29 23.494 29 27.4892C29 28.2882 28.3508 28.936 27.55 28.936C26.8064 28.936 26.1935 28.3775 26.1098 27.6579L26.0926 27.2342C25.9652 25.0401 24.2045 23.2833 22.0056 23.1562L21.75 23.1488H7.25C4.84756 23.1488 2.9 25.0921 2.9 27.4892C2.9 28.2882 2.25081 28.936 1.45 28.936C0.649187 28.936 0 28.2882 0 27.4892C0 23.5991 3.07734 20.4262 6.93551 20.2619L7.25 20.2552H21.75H7.25ZM14.5 0C19.3049 0 23.2 3.88653 23.2 8.68081C23.2 13.4751 19.3049 17.3616 14.5 17.3616C9.69512 17.3616 5.8 13.4751 5.8 8.68081C5.8 3.88653 9.69512 0 14.5 0ZM14.5 2.89359C11.2967 2.89359 8.69995 5.48461 8.69995 8.6808C8.69995 11.877 11.2967 14.468 14.5 14.468C17.7032 14.468 20.3 11.877 20.3 8.6808C20.3 5.48461 17.7032 2.89359 14.5 2.89359Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </header>
    <div class="sep120" v-if="!isAuth"></div>
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("monetization.title") }}</h1>
      </div>
    </div>
    <div class="extra-monetization">
      <div class="extra-monetization-banner">
        <div class="extra-monetization-banner__number">
          <img :src="require('@/assets/images/monetization/40.svg')" alt="" />
        </div>
        <div class="extra-monetization-banner__txt">
          <div class="extra-monetization-banner__title">
            {{ $t("monetization.title") }} <span>BY Partners.House</span>
          </div>
          <div
            class="extra-monetization-banner__desc"
            v-html="$t('monetization.banner')"
          ></div>
        </div>
      </div>
      <div class="sep"></div>

      <div class="section">
        <h2>{{ $t("monetization.how_it_works") }}</h2>
        <div class="row _offset30">
          <div class="col-12 col-lg-5">
            <div class="entry-content pt-lg-4">
              <p v-html="$t('monetization.explanation')"></p>
            </div>
          </div>
          <div class="col-12 col-lg-6 offset-lg-1">
            <div class="extra-monetization-about">
              <div class="extra-monetization-about__item">
                <figure>
                  <img
                    :src="require('@/assets/images/monetization/icon-1.svg')"
                    alt=""
                  />
                </figure>
                <p>{{ $t("monetization.step1") }}</p>
              </div>
              <div class="extra-monetization-about__item">
                <figure>
                  <img
                    :src="require('@/assets/images/monetization/icon-2.svg')"
                    alt=""
                  />
                </figure>
                <p>
                  {{ $t("monetization.step2") }}
                </p>
              </div>
              <div class="extra-monetization-about__item">
                <figure>
                  <img
                    :src="require('@/assets/images/monetization/icon-3.svg')"
                    alt=""
                  />
                </figure>
                <p>
                  {{ $t("monetization.step3") }}
                </p>
              </div>
              <div class="extra-monetization-about__item">
                <figure>
                  <img
                    :src="require('@/assets/images/monetization/icon-4.svg')"
                    alt=""
                  />
                </figure>
                <p>{{ $t("monetization.step4") }}</p>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="!isAuth"
          @click="$router.push('/registration')"
          class="btn mb-2 _mob100 _bg-green mt-3"
        >
          {{ $t("auth.sign_up") }}
        </button>
      </div>

      <div class="extra-monetization-case mt-5">
        <div class="row">
          <div class="col-12 col-lg-5">
            <a :href="caseLink" class="thumb"
              ><img
                :src="
                  require(`@/assets/images/monetization/case-${
                    $i18n.locale === 'ru' ? 'ru' : 'en'
                  }.webp`)
                "
                alt=""
            /></a>
          </div>
          <div class="col-12 col-lg-7">
            <h2 class="mb-4">{{ $t("monetization.case_title") }}</h2>
            <p class="pb-3">{{ $t("monetization.case_info") }}</p>
            <a :href="caseLink" target="_blank" class="btn mt-auto">{{
              $t("monetization.watch_case")
            }}</a>
          </div>
        </div>
      </div>

      <div class="section mt-5 _settings">
        <h2 class="pb-3 mb-0">{{ $t("monetization.how_to_setup") }}</h2>
        <div class="row _offset30">
          <div class="col-12 col-lg-6 d-flex">
            <div class="read-guide">
              <div class="read-guide__head">
                <div class="read-guide__logo">
                  <img
                    :src="require('@/assets/images/monetization/keitaro.svg')"
                    alt=""
                  />
                </div>
                <div class="read-guide__btn">
                  <a :href="keitaroGuideLink" target="_blank" class="btn"
                    ><span class="icon-view mr-2"></span
                    >{{ $t("monetization.read_guide") }}</a
                  >
                </div>
              </div>
              <div class="read-guide__desc mb-2">
                {{ $t("monetization.keitaro_title") }} <strong>Keitaro</strong>
              </div>
              <a :href="keitaroVideoLink" target="_blank" class="thumb">
                <span class="thumb__play"></span>
                <img
                  :src="
                    require('@/assets/images/monetization/video-thumb-1.webp')
                  "
                  alt=""
                />
              </a>
            </div>
          </div>
          <div class="col-12 col-lg-6 d-flex">
            <div class="read-guide">
              <div class="read-guide__head">
                <div class="read-guide__logo">
                  <img
                    :src="require(`@/assets/images/monetization/${isDarkTheme ? 'binom-d.png':'binom.webp'}`)"
                    alt=""
                  />
                </div>
                <div class="read-guide__btn">
                  <a :href="binomGuideLink" target="_blank" class="btn"
                    ><span class="icon-view mr-2"></span
                    >{{ $t("monetization.read_guide") }}</a
                  >
                </div>
              </div>
              <div class="read-guide__desc mb-2">
                {{ $t("monetization.keitaro_title") }} <strong>Binom</strong>
              </div>

              <a :href="binomVideoLink" target="_blank" class="thumb">
                <span class="thumb__play"></span>
                <img
                  :src="
                    require('@/assets/images/monetization/video-thumb-2.webp')
                  "
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <aside class="sidebar" :class="{ active: openedSidebar }">
      <div class="sidebar__content mt-2">
        <select-language leftMenu />

        <nav class="sidebar__entry entry-menu">
          <ul>
            <li class="mt-3">
              <router-link to="/login" class="btn _entry">{{
                $t("landing.login")
              }}</router-link>
            </li>
            <li class="mt-3">
              <router-link to="/registration" class="btn _registration">{{
                $t("landing.register")
              }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="sidebar__manager">
          <div class="manager">
            <div class="manager__item">
              <div class="manager__icon icon-telegram align-center">
                <svg
                  v-svg
                  role="presentation"
                  size="0 0 20 18"
                  symbol="telegram"
                ></svg>
              </div>
              <div class="manager__label">{{ $t("landing.manager") }}</div>
              <a
                href="https://telegram.me/Leo31PH"
                target="_blank"
                class="manager__link"
                >@Leo31PH</a
              >
            </div>
            <div class="manager__item mt-2">
              <div class="manager__icon icon-telegram align-center">
                <svg
                  v-svg
                  role="presentation"
                  size="0 0 20 18"
                  symbol="telegram"
                ></svg>
              </div>
              <div class="manager__label">{{ $t("landing.manager") }}</div>
              <a
                href="https://telegram.me/PH_support_Dmitriy"
                target="_blank"
                class="manager__link"
                >@PH_support_Dmitriy</a
              >
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a
                href="https://telegram.me/publisher_push_house"
                target="_blank"
                class="manager__link"
                >@publisher_push_house</a
              >
            </div>
          </div>
        </div>
      </div>
    </aside>
    <div
      class="menu-bg"
      @click="openedSidebar = false"
      :class="{ active: openedSidebar }"
    ></div>
  </div>
</template>

<script>
import SelectLanguage from "@/components/SelectLanguage.vue";
import { mapGetters } from 'vuex';

export default {
  name: "ExtraMonetization",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("monetization.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("monetization.title")}`;
  },
  components: {
    SelectLanguage,
  },
  data: () => ({
    openedSidebar: false,
  }),
  mounted() {
    if (this.$route.query && this.$route.query?.rid) {
      localStorage.setItem("userPid", this.$route.query.rid);
    }
    if (this.$route.params && this.$route.params?.id) {
      localStorage.setItem("userPid", this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters({
       isDarkTheme: 'user/isDarkTheme'
    }),
    isAuth() {
      return localStorage.getItem("authToken") ? true : false;
    },
    caseLink() {
      return this.$i18n.locale === "ru"
        ? "http://guide.partners.house/case?lang=ru"
        : "http://guide.partners.house/case";
    },
    keitaroGuideLink() {
      return this.$i18n.locale === "ru"
        ? "http://guide.partners.house/keitaro?lang=ru"
        : "http://guide.partners.house/keitaro";
    },
    keitaroVideoLink() {
      return this.$i18n.locale === "ru"
        ? "https://www.youtube.com/watch?v=ifD0TMsmX7s"
        : "https://www.youtube.com/watch?v=FutCamf4w38";
    },
    binomGuideLink() {
      return this.$i18n.locale === "ru"
        ? "http://guide.partners.house/binom?lang=ru"
        : "http://guide.partners.house/binom";
    },
    binomVideoLink() {
      return this.$i18n.locale === "ru"
        ? "https://www.youtube.com/watch?v=zfhuGvI4mYk"
        : "https://www.youtube.com/watch?v=VhdSmkASnh0";
    },
  },
};
</script>

<style lang="scss" scoped>
.sep120 {
  height: 120px;
}
.home-page .manager .manager__item .manager__link,
.home-page .manager .manager__chat .manager__link {
  color: #1e1e1e;
}
.sidebar__entry ul li .btn._entry {
  border: 1px solid #000109;
  width: 100%;
  color: #000109;
  background: transparent;
}
.sidebar__entry ul li .btn._registration {
  width: 100%;
  background: rgba(0, 1, 9, 0.78);
  border-color: #000109;
}
.header__login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  border-radius: 10px;
  background: linear-gradient(
    225.37deg,
    rgba(159, 51, 149, 0.5) 6.25%,
    rgba(101, 27, 119, 0.5) 94.32%
  );
  svg {
    width: 18px;
  }
}

.header__entry.entry-menu {
  display: none;
  ul {
    display: flex;
    margin: 0;
    column-gap: 10px;
  }
}

.header__manager {
  order: 0;
}

.main-logo {
  display: none;
}

.mobile-logo {
  display: block;
  width: 144px;
  height: auto;
}

@media screen and (min-width: 1200px) {
  .sidebar,
  .mobile-logo,
  a.header__login {
    display: none;
  }
  .header__entry.entry-menu,
  .main-logo {
    display: block;
  }

  .extra-monetization .section {
    padding: 40px 60px;
  }
}
.extra-monetization .entry-content {
  line-height: 1.7;
  font-size: 16px;
}

@media screen and (max-width: 991px) {
  .extra-monetization-about {
    padding-top: 30px;
  }
}
.extra-monetization-case {
  padding: 0 20px;
}

@media screen and (max-width: 991px) {
  .extra-monetization-case {
    padding: 0;
  }
  .extra-monetization-case .thumb {
    margin-bottom: 20px;
  }
}

.extra-monetization-case .thumb {
  padding-bottom: 52%;
}

.extra-monetization-case .thumb img {
  border-radius: 10px;
}

.extra-monetization .section {
  background: #f7f7f9;
  border-radius: 15px;
  padding: 20px;
}

.extra-monetization-about .extra-monetization-about__item {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.extra-monetization-about .extra-monetization-about__item:not(:last-child) {
  margin-bottom: 35px;
}
.extra-monetization-about .extra-monetization-about__item figure {
  border-radius: 7px;
  background: linear-gradient(
    227deg,
    #fff 3.18%,
    rgba(255, 255, 255, 0) 100.15%
  );
  box-shadow: 0px 4px 35px 0px #e9eaef;
  min-width: 56px;
  width: 56px;
  height: 56px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .extra-monetization-about .extra-monetization-about__item figure {
    margin-right: 20px;
  }
}
.extra-monetization-about .extra-monetization-about__item p {
  margin: 0;
}

.extra-monetization-banner {
  background: url("~@/assets/images/monetization/banner-bg.webp") center center
    no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 75px;
}
@media screen and (max-width: 991px) {
  .extra-monetization-banner {
    padding-left: 30px;
    padding-right: 30px;
    background-position: 0 center;
  }
}
@media screen and (max-width: 767px) {
  .extra-monetization-banner {
    flex-direction: column;
    text-align: center;
    background-position: 3% center;
  }
}
.extra-monetization-banner .extra-monetization-banner__number {
  margin-right: 70px;
}
@media screen and (max-width: 991px) {
  .extra-monetization-banner .extra-monetization-banner__number {
    margin-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  .extra-monetization-banner .extra-monetization-banner__number {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.extra-monetization-banner .extra-monetization-banner__number img {
  display: block;
}
@media screen and (max-width: 767px) {
  .extra-monetization-banner .extra-monetization-banner__number img {
    width: 200px;
  }
}
.extra-monetization-banner .extra-monetization-banner__title {
  font-weight: 900;
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.extra-monetization-banner .extra-monetization-banner__title span {
  display: inline-block;
}
@supports (-webkit-text-stroke: 1px #fff) {
  .extra-monetization-banner .extra-monetization-banner__title span {
    text-stroke: 1px #fff;
    -webkit-text-stroke: 1px #fff;
    color: transparent;
  }
}
.extra-monetization-banner .extra-monetization-banner__desc {
  color: #dde1ec;
  line-height: 1.4;
  max-width: 500px;
}
.extra-monetization-banner .extra-monetization-banner__desc ::v-deep span {
  display: inline-block;
  border-radius: 50px;
  background: #3a9f8d;
  color: #fff;
  font-weight: 500;
  padding: 3px 10px;
}

.read-guide {
  width: 100%;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 15px 80px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  padding: 30px 30px 40px;
}

.read-guide .read-guide__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

@media screen and (max-width: 550px) {
  .read-guide .read-guide__head {
    flex-flow: column wrap;
    row-gap: 20px;
  }
}

.read-guide .thumb {
  padding-bottom: 56%;
}

.thumb .thumb__play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  border-radius: 11px;
  background: rgba(0, 0, 0, 0.8) url("~@/assets/images/monetization/play.svg")
    50% center no-repeat;
  width: 74px;
  height: 50px;
  margin-left: -37px;
  margin-top: -25px;
  transition: 0.3s;
}

.thumb img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
}

.section._settings {
  background: #f7f7f9 url("~@/assets/images/monetization/settings.svg") 100% 0
    no-repeat;
  background-size: 389px auto;
  padding-bottom: 20px;
  border-radius: 15px;
}

@media screen and (max-width: 1200px) {
  .section._settings {
    padding: 20px;
  }
}
</style>
